import Link from "next/link";
import React, { useContext } from "react";
import AttachmentsContext from "../../../components/certifications/AttachmentsContext";
import paths from "../../../config/paths";
import useUser, { permissions } from "../../../hooks/useUser";
import isGranted from "../../../server/security/IsGranted";

function DocumentationCheck() {
  const context = useContext(AttachmentsContext);
  const { user } = useUser();

  return !user ||
    isGranted(user, permissions.access_dashboard) ||
    context?.documentationSent ? null : (
    <div className="container my-2">
      <div className="alert alert-warning shadow-lg">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current flex-shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <span>
            Necesitas cargar tus documentos para completar el registro{" "}
            <Link href={paths.documentation} className="link">
              cargar ahora
            </Link>{" "}
          </span>
        </div>
      </div>
    </div>
  );
}

export default DocumentationCheck;
