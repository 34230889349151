import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { ReactPropTypes } from "react";

interface Props {
  white?: boolean;
}

const Loader: React.FC<Props & React.HTMLProps<HTMLElement>> = ({
  white,
  className,
}) => {
  return (
    <div className={classNames(className, white ? "spinner white" : "spinner")}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

export function Spinner(
  props: React.HTMLProps<HTMLElement>
): React.ReactElement {
  return (
    <FontAwesomeIcon
      icon={faSpinner}
      className={classNames("animate-spin mr-2", props.className)}
    />
  );
}

export default Loader;
