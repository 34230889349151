import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import Loader from "../Loader";
// import Loader from "../Loader";

interface props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: "primary" | "secondary" | "light" | "dark" | "link";
  scale?: "xs" | "sm" | "base" | "lg" | "xl" | "2xl";
  icon?: IconProp;
  iconPlacement?: "left" | "right";
  loading?: boolean;
}

const Button = ({
  color,
  scale,
  children,
  className,
  icon,
  iconPlacement,
  loading,
  ...props
}: props) => {
  //Defino las clases de acuerdo a las opciones pasadas
  const buttonClasses = new Set<string>();
  const iconClasses = new Set<string>();

  //Base
  buttonClasses.add(
    "relative border border-transparent font-light rounded-full focus:outline-none focus-ring-0 group inline-flex items-center justify-center"
  );

  //Tamaño
  scale = scale ?? "base"; //Defualt

  switch (scale) {
    case "xs":
      buttonClasses.add("py-1 px-1 text-sm sm:text-xs");
      iconClasses.add("h-4 w-auto");
      break;
    case "sm":
      buttonClasses.add("py-2 px-2 text-sm sm:text-sm");
      iconClasses.add("h-5 w-auto");
      break;
    case "base":
      buttonClasses.add("py-4 px-4 text-sm sm:text-base");
      iconClasses.add("h-5 w-auto");
      break;
    case "lg":
      buttonClasses.add("py-6 px-5 text-sm sm:text-base");
      iconClasses.add("h-5 w-auto");
      break;
  }

  //Color
  color = color ?? "primary"; //Defualt
  switch (color) {
    case "primary":
      buttonClasses.add(
        "bg-primary hover:bg-primary-dark focus:ring-primary focus:ring-2 focus:ring-offset-2 text-white disabled:bg-cru-400"
      );
      iconClasses.add("group-hover:text-primary-light text-cru-100");
      break;
    case "secondary":
      buttonClasses.add(
        "bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-2 focus:ring-offset-2 text-white disabled:opactiy-40 disabled:bg-purple-400"
      );
      iconClasses.add("group-hover:text-purple-500 text-purple-400");
      break;
    case "light":
      buttonClasses.add(
        "bg-white border-gray-400 hover:bg-gray-200 focus:ring-gray-500 focus:ring-2 focus:ring-offset-2 disabled:bg-gray-200"
      );
      iconClasses.add("group-hover:text-gray-700 text-gray-700");
      break;
  }

  //Icon placement
  iconPlacement = iconPlacement ?? "left";
  buttonClasses.add(iconPlacement === "right" ? "flex-row-reverse" : "");

  //Disabled
  props.disabled && buttonClasses.add("opacity-50 cursor-not-allowed");

  //
  className && buttonClasses.add(className);
  return (
    <button className={classNames(Array.from(buttonClasses))} {...props}>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          className={classNames(
            "ml-2",
            Array.from(iconClasses),
            loading ? "invisible" : ""
          )}
        />
      )}
      {children && (
        <span
          className={classNames("mx-2 uppercase", loading ? "invisible" : "")}
        >
          {children}
        </span>
      )}
      {loading && <Loader white />}
    </button>
  );
};

export default Button;
