import classNames from "classnames";
import Privacy from "../Privacy";

export default function Footer({ className }: { className?: string }) {
  return (
    <footer className={classNames(className, "grow-0 bg-gray-100 mt-6")}>
      <div className="container py-3 text-gray-400 text-center text-xs divide-x">
        <span className="px-2">Parte de la Cruz Roja Uruguaya</span>
        <Privacy className="underline px-2" />
        <a className="underline px-2" href="https://goole.com.uy">
          Voluntariado
        </a>
      </div>
    </footer>
  );
}
