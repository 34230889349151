import { getSession, useSession } from "next-auth/react";

export enum permissions {
  is_admin,
  access_dashboard,
  manage_volunteers,
  manage_staff,
  is_person,
}

function useUser() {
  const session = useSession();

  async function reloadSession() {
    await getSession();
    const event = new Event("visibilitychange");
    document.dispatchEvent(event);
  }

  return {
    user: session.data?.user,
    status: session.status,
    reloadSession,
  };
}

export default useUser;
