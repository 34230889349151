import React from "react";
import DocumentationCheck from "../../features/home/components/DocumentationCheck";
import { AttachmentsContextProvider } from "../certifications/AttachmentsContext";
import Footer from "./Footer";
//import WhatsAppHelp from "../WhatsAppHelp";
import Header from "./Header";

const HomeLayout = (props: { children: React.ReactNode }): JSX.Element => {
  return (
    <AttachmentsContextProvider>
      <div className="min-h-screen h-full flex flex-col">
        <Header />
        <DocumentationCheck />
        {props.children}
        <Footer />
      </div>
    </AttachmentsContextProvider>
  );
};

export default HomeLayout;
