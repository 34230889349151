import React from "react";
import Modal from "./layout/Modal";

function Privacy({ className }: { className?: string }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <button onClick={() => setOpen(true)} className={className}>
        Política de privacidad
      </button>
      <Modal title="Política de privacidad" open={open} setOpen={setOpen} >
        <div className="text-justify text-xs space-y-2">
          <p>
            Cruz Roja Uruguaya declara que los datos enviados a través del
            formulario de la plataforma “Sistema de Información de Voluntariado
            (SIVo)” recibirá un tratamiento de protección y confidencialidad
            bajo las leyes nacionales e internacionales de la Federación
            Internacional de la Cruz Roja y la Media Luna Roja, quedando
            prohibida la venta o difusión de los mismos a cualquier otro
            organismo ajeno a nuestra propia Sociedad Nacional, y en caso de así
            requerirlo será notificado con anterioridad para obtener su
            aprobación, siendo esta notificación específica para cada
            circunstancia que se necesite.
          </p>

          <p>
            Toda la información será tratada bajo las siguientes normativas:
            <ul>
              <li>La Declaración Universal de los derechos humanos.</li>
              <li>
                La Convención Americana sobre Derechos Humanos o Pacto de San
                José de Costa Rica.
              </li>
              <li>
                La Constitución de la República, en especial su art. 72. La Ley
                N° 18.331 de Protección de Datos Personales y Acción de Habeas
                Data (LPDP) de 11 de agosto de 2008.
              </li>
              <li>
                Los Decretos N° 664/008 de 22 de diciembre de 2008 y N° 414/2009
                de 31 de agosto de 2009. Los Arts. 152 a 156 de la Ley N° 18.719
                de 27 de diciembre de 2010, que introducen modificaciones a la
                Ley N° 18.331.
              </li>
              <li>
                El art. 158 literales B) y C) de la Ley N° 18.719 de 27 de
                diciembre de 2010, sobre intercambio de información pública o
                privada entre organismos públicos, estatales o no.
              </li>
              <li>
                La Ley N° 19.030 de 12/12/2012 regula la adhesión de Uruguay al
                Convenio Nº 108 y su Protocolo Adicional.
              </li>
            </ul>
          </p>
        </div>
      </Modal>
    </>
  );
}

export default Privacy;
