import { Role } from "@prisma/client";
import { Session } from "next-auth";
import { permissions } from "../../hooks/useUser";

function isGranted(user?: Session["user"], permission?: permissions): boolean {
  if (!user) return false;

  switch (permission) {
    case permissions.is_admin:
      return !!user.roles?.includes(Role.ADMIN);
      break;
    case permissions.manage_volunteers:
      return !!(
        user.roles?.includes(Role.ADMIN) ||
        user.roles?.includes(Role.VOLUNTEER_MANAGER)
      );
      break;

    case permissions.manage_staff:
      return !!(
        user.roles?.includes(Role.ADMIN) ||
        user.roles?.includes(Role.STAFF_MANAGER)
      );
      break;

    case permissions.access_dashboard:
      return !!(
        user.roles?.includes(Role.ADMIN) ||
        user.roles?.includes(Role.STAFF_MANAGER) ||
        user.roles?.includes(Role.VOLUNTEER_MANAGER)
      );
      break;

    case permissions.is_person:
      return !!user.document;
      break;

    default:
      return true;
      break;
  }
}

export default isGranted;
