const paths = {
  home: "/",
  login: "/api/auth/signin",
  register: "/registro",
  update: "/actualizar",
  health: "/ficha-medica",
  documentation: "/documentacion",
  certifications: "/certificaciones",
  volunteering: "/voluntariado",
  profile: "/config/profile",
  admin_users: "/admin/usuarios",
  admin_volunteers: "/admin/voluntarios",
  admin_staff: "/admin/staff",
  admin_users_certifications: "/admin/certificaciones/usuarios",
  admin_activities: "/admin/actividades",
};

export default paths;
