import { Menu, Transition } from "@headlessui/react";
import Avatar from "boring-avatars";
import classNames from "classnames";
import { signOut } from "next-auth/react";
import Link from "next/link";
import { Fragment } from "react";
import paths from "../../config/paths";
import useUser, { permissions } from "../../hooks/useUser";
import isGranted from "../../server/security/IsGranted";
import Button from "../forms/Button";

function UserProfileMenu() {
  let navigation = [{ name: "Modificar tus datos", href: paths.profile }];

  let { user } = useUser();

  return (
    <div className="ml-4 flex items-center md:ml-6">
      {/* Profile dropdown */}
      <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-offset-2">
            <Avatar
              colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
              name={user?.id}
              size={40}
              variant="beam"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none px-2">
            {navigation.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <Link
                    href={item.href}
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700"
                    )}
                  >
                    {item.name}
                  </Link>
                )}
              </Menu.Item>
            ))}
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => signOut({ callbackUrl: "/" })}
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                  )}
                >
                  Cerrar sesión
                </button>
              )}
            </Menu.Item>

            {isGranted(user, permissions.access_dashboard) && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    href={
                      isGranted(user, permissions.manage_volunteers)
                        ? paths.admin_volunteers
                        : paths.admin_staff
                    }
                  >
                    <Button className="w-full my-2">ADMINISTRADOR</Button>
                  </Link>
                )}
              </Menu.Item>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default UserProfileMenu;
