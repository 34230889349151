import { Certification } from "@prisma/client";
import React, { PropsWithChildren, useEffect, useState } from "react";
import useUser from "../../hooks/useUser";
import { trpc } from "../../utils/trcp";

type attachmentsContext = {
  certifications?: (Certification)[];
  refetch: () => void;
  documentationSent?: boolean;
};

const AttachmentsContext = React.createContext<attachmentsContext | undefined>(
  undefined
);

export default AttachmentsContext;

export function AttachmentsContextProvider({ children }: PropsWithChildren) {
  const [sent, setSent] = useState<boolean>();
  const { user } = useUser();

  const { data: certifications } =
    trpc.currentUser.certifications.getAll.useQuery(undefined, {
      enabled: !!user,
    });

  const trpcContext = trpc.useContext();
  function refetch() {
    trpcContext.currentUser.certifications.getAll.invalidate();
  }

  useEffect(() => {
    certifications &&
      setSent(
        certifications.some((c) => c.name === "Documento frente") &&
          certifications.some((c) => c.name === "Documento dorso") &&
          certifications.some((c) => c.name === "Acuerdo de voluntariado")
      );
  }, [certifications]);

  return (
    <AttachmentsContext.Provider
      value={{
        certifications,
        refetch: refetch,
        documentationSent: sent,
      }}
    >
      {children}
    </AttachmentsContext.Provider>
  );
}
